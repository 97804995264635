import React, { useState, useEffect } from 'react';
import logo from './logo.png';
import { Input, List, Avatar, Badge, Button, Tooltip } from 'antd';
import { useDebouncedCallback } from 'use-debounce';
import { formatDistanceToNow, format } from 'date-fns'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGlobeAmericas, faAt, faEnvelopeSquare, faUser } from '@fortawesome/free-solid-svg-icons'

import GitLabService from './services/GitLabService';
import { IUserDetails } from './interfaces/IUserDetails';

import './App.css';
import 'antd/dist/antd.dark.css';

const { Search } = Input;

function App() {
  const [queryText, setQueryText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);
  const [queryResults, setQueryResults] = useState<IUserDetails[]>();
  const [currentPage, setCurrentPage] = useState(1);
  const [hasMorePages, setHasMorePages] = useState(true);

  const perPage = 10;
  
  const debouncedQueryText = useDebouncedCallback(
    (value) => {
      setQueryText(value);
    },
    2000
  );

  useEffect(() => {
    if (!queryText)
    {
      setIsLoading(false);
      return;
    }
    
    setIsLoading(true);
    setCurrentPage(1);
    GitLabService.searchUsers(queryText, perPage, 1).then((data) => {
      setIsLoading(false);
      setHasMorePages(data.length === perPage);
      setQueryResults(data);
    });

  }, [queryText]);

  const onLoadMore = () => {
    setIsPageLoading(true);
    const newPage = currentPage + 1;
    setCurrentPage(newPage);

    GitLabService.searchUsers(queryText, perPage, newPage).then((data) => {
      setIsPageLoading(false);
      setHasMorePages(data.length === perPage);
      setQueryResults(queryResults?.concat(data));
    });
  }



  return (
    <div>
      <header>
        <img src={logo} className="App-logo" alt="logo" />
        
        
      </header>
      <div className="Search">
        <Search 
          placeholder="Name or Email Address to Search" 
          loading={isLoading} 
          allowClear 
          style={{width: "30em"}} 
          onChange={(e) => debouncedQueryText(e.target.value)}
          onSearch={(query) => setQueryText(query)} />
      </div>
      
      {queryResults && <List
        itemLayout="horizontal"
        dataSource={queryResults}
        className="user-list"
        loadMore={isPageLoading ? <Button loading disabled>Loading...</Button> : (!isLoading && hasMorePages && <Button onClick={onLoadMore}>Load more...</Button>)}
        renderItem={item => (
          <List.Item>
            <div className="user-item-container">
              <Avatar src={item.avatar_url} className="user-avatar" size="large" />
              <span className="user-data-r1-c2"><FontAwesomeIcon icon={faAt} title="Username" /><a href={item.html_url} target="_blank" rel="noreferrer">{item.login}</a></span>
              {item.name && <span className="user-data-r1-c3"><FontAwesomeIcon icon={faUser} title="Name" />{item.name}</span>}
              {item.email && <span className="user-data-r1-c4"><FontAwesomeIcon icon={faEnvelopeSquare} title="Email Address" /><a href={"mailto:" + item.email}>{item.email}</a></span>}
              {item.location &&  <span className="user-data-r1-c5"><FontAwesomeIcon icon={faGlobeAmericas} title="Location" />{item.location}</span>}
              <span className="user-data-r2-c2"><a href={item.html_url + "?tab=repositories"} target="_blank" rel="noreferrer"><Badge count={item.public_repos} overflowCount={10000} showZero style={{ backgroundColor: "#283430", fontSize: "1.2em" }} /> Public Repos</a></span>
              {item.created_at && <span className="user-data-r2-c3"><Tooltip title={format(item.created_at, 'PPpp')}>Created: {formatDistanceToNow(item.created_at)} ago</Tooltip></span>}
              {item.updated_at && <span className="user-data-r2-c4"><Tooltip title={format(item.updated_at, 'PPpp')}>Updated: {formatDistanceToNow(item.updated_at)} ago</Tooltip></span>}
            </div>
          </List.Item>
        )}
      />}
      <br />
    </div>
  );
}

export default App;
