import { Octokit } from "octokit";
import { IUserDetails } from '../interfaces/IUserDetails';

// Usually you definitly wouldn't want to embed a token like this, but it's from a disposible GitLab account with little access granted
const octokit = new Octokit({ auth: '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'  });

const GitLabService = {

  searchUsers: async (searchQuery: string, perPage: number, page: number): Promise<IUserDetails[]> => {
    var userResults = await octokit.rest.search.users({ q: searchQuery, per_page: perPage, page: page });
    return await Promise.all(userResults.data.items.filter((user) => !!user.login).map(async (user) => {
      var userDetailsResults = await octokit.rest.users.getByUsername({ username: user.login });
      const userDetailsData = userDetailsResults.data;
      var userDetails : IUserDetails = {
        login: user.login,
        id: user.id,
        avatar_url: user.avatar_url,
        created_at: new Date(userDetailsData.created_at as string),
        updated_at: new Date(userDetailsData.updated_at as string),
        name: userDetailsData.name as string,
        email: userDetailsData.email as string,
        location: userDetailsData.location as string,
        html_url: userDetailsData.html_url as string,
        public_repos: userDetailsData.public_repos as number
      }
      return userDetails;
    }));
  }
}

export default GitLabService;
